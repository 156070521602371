import React from "react";

const Contact = () => {
  return (
    <>
      <a
        style={{ fontWeight: "bolder", color: "black" }}
        href="https://twitter.com/PandaGameStudio"
        target="_blank"
        rel="noopener noreferrer"
      >
        Twitter
      </a>
    </>
  );
};

export default Contact;
