import React, { Component } from "react";

class NoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
        <div className="container-fluid flex-column bg-light d-flex justify-content-center">
          <span role="img" aria-label="something went wrong" className="text-center">
            😢😢😢
          </span>
          <span role="img" aria-label="cri" className="font-weight-bold text-center">
            You took a wrong turn!
          </span>
        </div>
    );
  }
}

export default NoPage;
