import React from "react";
import { Route, Switch } from "react-router-dom";
import MainPage from "./MainPage.js";
import Header from "./Header";
import Footer from "./Footer";
import Contact from "./Contact";
import Jukebox from "./jukebox/Jukebox.js";
import NoPage from "./NoPage.js";

const App = () => {
  return (
    <>
      <Header />
      <Switch>
        <Route exact path="/" component={MainPage} />
        <Route exact path="/PGSJukebox" component={Jukebox} />
        <Route exact path="/Contact" component={Contact} />
        <Route component={NoPage} />
      </Switch>
      <Footer />
    </>
  );
};

export default App;
