import "./Jukebox.css";
import React from "react";

function Jukebox() {
  return (
    <div className="Page JukeboxTitle">
      This has been temporarily removed for security reasons :)
    </div>
  );
}

export default Jukebox;
