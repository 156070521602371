import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const HeaderBar = styled.div`
  background-color: black;
  padding: 10px;
  display: flex;
`;

const Image = styled.img`
  height: 5rem;
  color: #ffffff;
`;

class Header extends Component {
  render() {
    return (
      <HeaderBar>
        <Link to="/">
          <Image
            src="panda.png"
            className="HeaderIcon p-1"
            alt="Panda Game Studios"
          />
        </Link>
      </HeaderBar>
    );
  }
}

export default Header;
