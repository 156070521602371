import React from "react";
import styled from "styled-components";
import { BoldLink } from "./DefaultStyles";

const FooterBar = styled.footer`
  background-color: black;
  position: fixed;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  bottom: 0px;
  width: 100%;
  padding: 0.5rem;
`;

const GrayLink = styled(BoldLink)`
  color: lightgrey;
`;

const Footer = () => {
  return (
    <FooterBar>
      <GrayLink to="./Contact">Contact Us</GrayLink>
      <div style={{ color: "lightgrey" }}>©Panda Game Studios 2018-2021</div>
    </FooterBar>
  );
};

export default Footer;
