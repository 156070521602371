import React from "react";
import { BoldLink } from "./DefaultStyles";

const MainPage = () => {
  return (
    <>
      <h1>Welcome to Panda Game Studios!</h1>
      <p>It's pretty under construction, sorry.</p>
      <p>
        Go to{" "}
        <a style={{ color: "black", fontWeight: "bolder" }} href="./3d">
          3d
        </a>{" "}
        to see a small threeJS project!
      </p>
      <p>
        Alternatively, try out the
        <BoldLink to="./PGSJukebox"> our Spotify Jukebox</BoldLink>
      </p>
    </>
  );
};

export default MainPage;
